<script setup>
import AlertContainer from '~/components/alerts/AlertContainer'
import CookieBar from '~/components/cookieConsent/CookieBar'

const route = useRoute()
const centeredLogo = computed(() => route.name === 'campagne-slug')

const routes = [
  '/campagne/inloggen',
  '/campagne/aanmelden',
  '/campagne/betalen',
  '/campagne/gelukt',
  '/aanmelden',
  '/keuze',
  '/overzicht',
  '/betalen',
  '/gelukt',
]

const isRouterHappinessGuarantee = computed(() => {
  return routes.some((routeName) => routeName === route.path)
})
</script>

<template>
  <div class="layout layout--stripped">
    <BaseHeader
      :is-default="false"
      color="#000"
      :centered-logo="centeredLogo"
    />

    <main>
      <slot />
    </main>

    <BaseFooter
      :is-default="false"
      :is-guarantee="isRouterHappinessGuarantee"
    />
    <AlertContainer />
    <CookieBar />
  </div>
</template>

<style lang="scss" scoped>
.layout--stripped {
  min-height: 100vh;
}
</style>
